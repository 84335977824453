<nav id="main-nav" [class.active]="active">
  <ul>
    <li class="home nav-item">
      <a routerLink="/{{ $selectedLanguage()}}/">
        <img src="/assets/images/img-sps-logo.svg" alt="Logo SPS">
      </a>
    </li>

    @for (item of items; track item) {
      <li [class.active]="config?.selectedItem === item.label" class="desktop nav-item" (mouseenter)="selectItem(item)">
        <a [routerLink]="item.href">
          {{ item.label }}

          @if (item.children?.length) {
            <sps-icon icon="expand_more"></sps-icon>
          }
        </a>
      </li>
    }

    <li class="desktop nav-item">
      <a [routerLink]="contactUsPath" id="cta-button" sps-button blue>
        {{ i18n('GET_IN_TOUCH') | translate }}
      </a>
    </li>

    <li class="mobile">
      <sps-icon icon="search" class="hover"
                role="button"
                tabindex="0"
                aria-label="Open search dialog"
                (click)="showSearchBox()">
      </sps-icon>
    </li>

    <li class="mobile">
      <sps-icon [icon]="mobileNav.active ? 'close' : 'menu'"
                class="hover" role="button" tabindex="0"
                (keyup.enter)="mobileNav.toggleActive()"
                (click)="mobileNav.toggleActive()"
                [attr.aria-label]="(active ? 'Close' : 'Open') + ' menu'">
      </sps-icon>
    </li>

  </ul>
</nav>

<sps-mobile-nav #mobileNav></sps-mobile-nav>

@defer (when subNavActive) {
  @if (subNavActive) {
    <sps-sub-nav [config]="config" class="desktop" (emitter)="close()"/>
  }
} @placeholder {
  <div></div>
} @error {
  <div></div>
}
