import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { inject } from '@angular/core';
import { UserService } from '@services/user/user.service';
import { LOCALSTORAGE_SPS_USER_KEY, CURRENT_ENABLED_URL, USER_SESSION_EXPIRY_TIME } from '@core/config';

import { SpsStorageService } from '@services/storage/sps-storage.service';

import { CUSTOM_GPT } from '@core/config';

export const botAccessGuard: CanActivateFn = (route, state): boolean | UrlTree => {
  const router = inject(Router);

  if (CUSTOM_GPT.enabledPages.includes(state.url)) {
    const expires = Date.now() + USER_SESSION_EXPIRY_TIME;
    SpsStorageService.saveEntry(CURRENT_ENABLED_URL, { url:state.url, expires });
    return UserService.isLoggedIn || router.createUrlTree(['site/login']);
  }

  return true;
};
