import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  effect,
  HostListener,
  inject,
  OnInit,
  signal,
  Signal,
  untracked,
  WritableSignal,
} from '@angular/core';
import { AsyncPipe, DOCUMENT } from '@angular/common';
import { RouterLink } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';

import { take, takeUntil } from 'rxjs';

import { select, Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';

import { RootState } from '@store/index';
import { PageMetasActions, PageMetasSelectors } from '@store/page-metas';
import { RootActions } from '@store/root.actions';
import { CmsPageActions } from '@store/cms-pages';

import { LanguageService } from '@services/language/language.service';

import { BaseCmsComponent } from '@core/components';
import { ButtonComponent } from '@components/common/_buttons/button/button.component';
import { IconComponent } from '@components/common/icon/icon.component';
import { MobileNavComponent } from '@components/root/_navs/mobile-nav/mobile-nav.component';
import { SubNavComponent } from '@components/root/_navs/sub-nav/sub-nav.component';

import { MainNav } from '@interfaces/config/core/main-nav-config';
import { cloneDeep } from 'lodash-es';

@Component({
  standalone: true,
  selector: 'sps-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [IconComponent, MobileNavComponent, SubNavComponent, RouterLink, AsyncPipe, TranslateModule, ButtonComponent],
})
export class MainNavComponent extends BaseCmsComponent<MainNav.ComponentConfig> implements OnInit, AfterViewInit {
  public $selectedLanguage: Signal<string>;
  public $originalConfig: WritableSignal<MainNav.ComponentConfig> = signal(null);

  protected override readonly i18RootKey = 'GENERAL.NAVIGATION';
  private boundEscapeKeyListenerFn: () => void;

  private document = inject(DOCUMENT, { optional: true });
  private store: Store<RootState> = inject(Store);
  private actions$ = inject(Actions);
  private baseListItemsWidth: number;

  @HostListener('mouseleave')
  private leave(): void {
    if (this.active) {
      this.close();
    }
  }

  override ngOnInit(): void {
 
    this.$selectedLanguage = this.store.selectSignal(PageMetasSelectors.selectCurrentLanguage(true));

    this.initFetchConfig();
    this.subscribeToNavigation();
    this.getConfig();
    this.bindEscapeKeyListener();

    const listItems = document.getElementsByClassName('nav-item');
    this.baseListItemsWidth = Object.values(listItems).map(item => item.clientWidth).reduce((p, c) => p + c);
    
    this.adjustNavigationLayout();
  }

  get contactUsPath(): string {
    if (LanguageService.selectedLanguage?.toLowerCase() === 'de') {
      return '/de/kontakt';
    }

    return '/en/contact-us';
  }

  public override set config(config: MainNav.ComponentConfig | null) {
    this._config = config;
    this.removeEscapeKeyEventListener();

    if (config?.selectedItem) {
      this.addEscapeKeyEventListener();
    }

    this.cd.markForCheck();
  }

  override get config(): MainNav.ComponentConfig {
    return this._config;
  }

  get items(): MainNav.ConfigItem[] {
    return this.$originalConfig()?.items || [];
  }

  get active(): boolean {
    return !!this.config?.selectedItem;
  }

  get subNavActive(): boolean {
    return this.active && !!this.config?.items?.length;
  }

  public close(): void {
    this.config = cloneDeep(this.$originalConfig());
  }

  public selectItem(item: MainNav.ConfigItem): void {

    this.config = {
      selectedItem: item.label,
      description: item.description,
      field_is_featured:item.field_is_featured,
      items: item.children,
      cta: item.cta,
      secondcta: item.secondcta
    };
  }

  @HostListener('window:resize')
  private adjustNavigationLayout() {
    const viewportWidth = window.innerWidth;
    const mainNav = document.getElementById('main-nav');

    if (this.baseListItemsWidth > viewportWidth) {
      mainNav.classList.add('wrapped-items');
    } else {
      mainNav.classList.remove('wrapped-items');
    }
  }

  private getConfig(): void {
    const $currentMainNavConfig = this.store.selectSignal(PageMetasSelectors.selectCurrentMainNavConfig);

    effect(
      () => {
        const config = $currentMainNavConfig();

        if (config) {
          this.config = { selectedItem: null, items: config,description:null };
          untracked(() => this.$originalConfig.set(cloneDeep(this.config)));
        }
      },
      { injector: this.injector }
    );
  }

  private addEscapeKeyEventListener(): void {
    this.document.addEventListener('keyup', this.boundEscapeKeyListenerFn);
  }

  private removeEscapeKeyEventListener(): void {
    this.document.removeEventListener('keyup', this.boundEscapeKeyListenerFn);
  }

  private keyListenerFn(ev: KeyboardEvent): void {
    if (ev.key !== 'Escape') {
      return;
    }

    this.close();
  }

  private initFetchConfig(): void {
    this.store.pipe(select(PageMetasSelectors.selectCurrentMainNavConfig), take(1)).subscribe(config => {
      if (config) {
        // Already hydrated from TransferState
        return;
      }

      this.store.dispatch(PageMetasActions.fetchMainNavItems());

    });
  }

  private subscribeToNavigation(): void {
    this.actions$
      .pipe(takeUntil(this.destroyed$), ofType(CmsPageActions.fetchSuccess, CmsPageActions.fetchError))
      .subscribe(this.close.bind(this));
  }

  private bindEscapeKeyListener(): void {
    this.boundEscapeKeyListenerFn = this.keyListenerFn.bind(this);
  }

  public showSearchBox(): void {
    this.store.dispatch(RootActions.showSearchDialog());
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
  }
}
