import { Routes } from '@angular/router';

export const PAGES_ROUTES: Routes = [
  {
    path: 'about-us',
    children: [
      {
        path: 'news',
        data: { canonical: 'news' },
        loadComponent: () =>
          import('../news-center/components/news-index-page/news-index-page.component').then(m => m.NewsIndexPageComponent),
      },
      {
        path: 'global-locations',
        data: { canonical: 'global-locations' },
        loadComponent: () =>
          import('./about-us/components/global-locations/global-locations.component').then(m => m.GlobalLocationsComponent),
      },
    ],
  },
  {
    path: 'knowledge-center',
    data: { canonical: 'pages/knowledge-center' },
    loadComponent: () =>
      import('./knowledge-center/components/kc-index-page/kc-index-page.component').then(m => m.KCIndexPageComponent),
  },
  {
    path: 'connected-workplace-event',
    data: { canonical: 'pages/connected-workplace-event' },
    loadComponent: () =>
      import('./multi-form/components/mf-index-page/mf-index-page.component').then(m => m.MFIndexPageComponent),
  },


  { path: '**', redirectTo: '/site/404' },
];
