import { CARDS_DE, CONTACT_PAGE_DE, CORE_DE, FORMS_DE, GLOBAL_LOCATIONS_DE, KNOWLEDGE_CENTER_DE, NEWS_DE, SHARED_DE } from './de';

export const DE = {
  GENERAL: {
    CARDS: CARDS_DE,
    FORMS: FORMS_DE,
    NAVIGATION: {
      GET_IN_TOUCH: 'Kontaktieren Sie uns',
      CONTACT_US :"Kontaktieren Sie uns",
      READ_MORE: 'Mehr erfahren',
      MOBILE: {
        LINK_TEXT: 'Mehr erfahren',
      },
    },
    WEBINAR:{
      DO_NOT_SHOW_TEXT:"Nicht mehr anzeigen",
    },
    NEWSLETTER_LINK_TEXT: 'Abonnieren Sie unseren Newsletter',
    SEARCH: {
      SEARCH_SPS: 'SPS Suche',
      SEARCH_BUTTON: 'Suche',
      SEARCH_LINK_DESCRIPTION:'Suchen Sie ein Whitepaper, eine Fallstudie oder eine Marktforschung?',
      SEARCH_VISIT_OUR_KNOWLEDGE_CENTER:'Besuchen Sie unser Wissenszentrum!',
      POPULAR: 'Meist gesucht:',
      TERMS: {
        DIGITAL_TRANSFORMATION: 'Digitale Transformation',
        BANKING_SOLUTIONS: 'Bankenlösungen',
        'SPS CAREERS': 'Karriere',
      },
    },
    MINUTES: '{{minutes}} Minuten',
  },
  MODULES: {
    CORE: CORE_DE,
    KNOWLEDGE_CENTER: KNOWLEDGE_CENTER_DE,
    NEWS: NEWS_DE,
    GLOBAL_LOCATIONS: GLOBAL_LOCATIONS_DE,
    CONTACT_PAGE: CONTACT_PAGE_DE,
  },
  SHARED: SHARED_DE,
};
